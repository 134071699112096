
  import {
    defineComponent,
    ref,
    onMounted,
    computed,
    onBeforeUnmount,
    reactive,
  } from 'vue';
  import { useRouter } from 'vue-router';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';
  import { Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import ShipmentsFilter from '@/components/dropdown/_ShipmentsFilter.vue';
  import jsPDF from 'jspdf';
  import printJs from 'print-js';
  import { reinitializeComponents } from '@/core/plugins/keenthemes';

  export default defineComponent({
    name: 'shipments-listing',
    components: {
      ShipmentsFilter,
      Datatable,
      Field,
      Form,
    },
    async setup() {
      const { t, te, locale } = useI18n();
      const store = useStore();
      const router = useRouter();
      const { can } = useAbility();
      const bulkApproveButton = ref<HTMLButtonElement | null>(null);
      const statuses = ref([]);
      const statusId = ref('');

      const selectedCount = computed(() => {
        return statuses.value.length;
      });

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const disClass = (isEditable) => {
        if (!isEditable)
          return 'btn btn-icon btn-bg-light btn-active-color-primary btn-sm disabled';
        else return 'btn btn-icon btn-bg-light btn-active-color-primary btn-sm';
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('shipmentsListing'), [
          translate('shipments'),
        ]);
      });

      const searchItems = async (input) => {
        await store.dispatch(Actions.GET_SUPPLIER_SHIPMENTS, input);
        if (input === '') {
          await store.dispatch(Actions.GET_SUPPLIER_SHIPMENTS);
        }
      };

      onMounted(async () => {
        onBeforeUnmount(() => {
          store.dispatch(Actions.RESET_SHIPMENTS_STORE);
        });

        loading.value = true;
        await store.dispatch(Actions.GET_SUPPLIER_SHIPMENTS);
        await store.dispatch(Actions.GET_ALL_SHIPMENT_STATUSES, 'gw');
        await store.dispatch(Actions.EXPORT_SHIPMENTS, 'supplier');
        setTimeout(() => {
          loading.value = false;
        }, 4000);

        reinitializeComponents();
      });

      const loading = ref(false);
      const tableHeader = ref([
        {
          key: 'checkbox',
          sortable: true,
        },
        {
          name: translate('Shipment ID'),
          key: 'tracknumber',
          sortable: true,
        },
        {
          name: translate('date'),
          key: 'eventDate',
          sortable: true,
        },
        {
          name: translate('Driver Name'),
          key: 'Captain',
          sortable: false,
        },
        {
          name: translate('Office Name'),
          key: 'officeName',
          sortable: false,
        },
        {
          name: translate('shipmentType'),
          key: 'shipmentType',
          sortable: false,
        },
        {
          name: translate('numberOfPackages'),
          key: 'numberOfPackages',
          sortable: false,
        },
        {
          name: translate('status'),
          key: 'status',
          sortable: false,
        },
        {
          name: translate('actions'),
          key: 'actions',
        },
      ]);

      const formatDate = (date) => {
        return new Date(date).toISOString().slice(0, 10);
      };

      const packagesHeader = reactive({
        'Shipment ID': 'shipmentTrackNumber',
        'Package ID': 'trackNumber',
        'Customer Name': 'name',
        'Phone Number': 'recipientPhoneNumber',
        City: 'areaName',
        'Driver Name': 'driverName',
        'Office Name': 'officeName',
        'Passport Number': 'passportNumber',
        Status: 'internalStatus',
        Date: {
          field: 'createdAt',
          callback: (value) => {
            return formatDate(value);
          },
        },
      });

      const excelHeader = reactive({
        'Shipment ID': 'tracknumber',
        'Shipment Type': 'shipmentType',
        Driver: 'driverName',
        'Number Of Packages': 'numberOfPackages',
        Status: 'status',
        Date: {
          field: 'createdAt',
          callback: (value) => {
            return formatDate(value);
          },
        },
      });

      const packageData = ref([]);

      const printPackages = async (shipment) => {
        packageData.value = [];
        const data = await store.dispatch(
          Actions.EXPORT_PACKAGES_SUPPLIER_SHEET,
          shipment.tracknumber
        );
        shipment.packageData = data;
        shipment.isExport = true;
        return true;
      };

      const printLoading = ref(false);

      const printBtn = async (shipment) => {
        printLoading.value = true;
        const packagesGroup = await store.dispatch(
          Actions.GET_ALL_SHIPMENT_PROPERTIES,
          shipment.id
        );
        shipment.packages = packagesGroup;

        if (shipment.packages && shipment.packages.length > 0) {
          const doc = new jsPDF();
          doc.setFontSize(12);

          shipment.packages.forEach((packageData, index) => {
            if (index > 0) {
              doc.addPage();
            }

            doc.addImage(packageData['qrCodeImage'], 'png', 15, 75, 75, 75);
            doc.text('Holder Name: ' + packageData.holderName, 20, 25);
            doc.text('Track Number : ' + packageData.trackNumber, 20, 35);
            doc.text('Document ID : ' + packageData.docId, 20, 45);
            doc.text('Phone : ' + packageData.recipientPhoneNumber, 20, 55);
            doc.text('Address : ' + packageData.address, 20, 65);
            doc.text('Notes : ' + packageData.notes, 20, 75);

            doc.autoPrint();
          });

          const data = doc.output('blob');
          const blobUrl = URL.createObjectURL(data);

          printJs({ printable: blobUrl, type: 'pdf' });

          await store.dispatch(Actions.UPDATE_SHIPMENT, {
            data: { isPrinted: true },
            id: shipment.id,
          });

          printLoading.value = false;

          await store.dispatch(Actions.GET_SUPPLIER_SHIPMENTS);
          reinitializeComponents();
        } else {
          console.error('No packages found for printing.');
        }
      };

      const toggleStatusAuction = async (shipment) => {
        const result = await Swal.fire({
          title: translate('areYouSureQ'),
          icon: 'warning',
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: translate('confirm'),
          cancelButtonText: translate('cancel'),
          customClass: {
            confirmButton: 'btn fw-bold btn-warning',
            cancelButton: 'btn btn-light',
          },
        });

        if (result.value) {
          loading.value = true;
          await store.dispatch(Actions.DELETE_SHIPMENT, shipment);
          await store.dispatch(Actions.GET_SUPPLIER_SHIPMENTS);
          loading.value = false;
          const [errorName] = Object.keys(store.getters.getErrors);
          const error = store.getters.getErrors[errorName];
          if (error) {
            Swal.fire({
              text: translate(error[0]),
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: translate('tryAgainExcl'),
              customClass: {
                confirmButton: 'btn fw-bold btn-light-danger',
              },
            });
          }
        }
      };

      const editAuction = (id) => {
        router.push({ name: 'shipment-editing', params: { id } });
      };

      const goTo = (pageName) => {
        router.push({ name: pageName });
      };

      const pageChanged = async (val) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_SHIPMENT_CURRENT_PAGE, val);
        await store.dispatch(Actions.GET_SUPPLIER_SHIPMENTS);
        loading.value = false;
      };

      const onTypeChange = async (value) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_SHIPMENTS_FILTER, value);
        await store.dispatch(Actions.GET_SUPPLIER_SHIPMENTS);
        await store.dispatch(Actions.EXPORT_SHIPMENTS, 'supplier');
        loading.value = false;
      };

      const onReset = async (value) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_SHIPMENTS_FILTER, value);
        await store.dispatch(Actions.GET_SUPPLIER_SHIPMENTS);
        await store.dispatch(Actions.EXPORT_SHIPMENTS, 'supplier');
        loading.value = false;
      };

      const changeStatus = async () => {
        if (bulkApproveButton.value) {
          // eslint-disable-next-line
          bulkApproveButton.value!.disabled = true;
          bulkApproveButton.value.setAttribute('data-kt-indicator', 'on');
        }

        const payload = {
          statusId: statusId.value,
          shipments: statuses.value,
        };
        await store.dispatch(Actions.CHANGE_STATUS, payload);
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          bulkApproveButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          bulkApproveButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_CHANGED_STATUSES'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(async () => {
            loading.value = true;
            await store.dispatch(Actions.GET_SUPPLIER_SHIPMENTS);
            loading.value = false;
            //Deactivate indicator
            bulkApproveButton.value?.removeAttribute('data-kt-indicator');
            // eslint-disable-next-line
            bulkApproveButton.value!.disabled = false;
          });
        }
      };

      return {
        tableData: computed(() => store.getters.shipmentsList),
        tableHeader,
        selectedCount,
        printPackages,
        packagesHeader,
        packageData,
        bulkApproveButton,
        toggleStatusAuction,
        changeStatus,
        statuses,
        statusId,
        translate,
        totalItems: computed(() => store.getters.shipmentsListCount),
        pageChanged,
        input: '',
        goTo,
        searchItems,
        editAuction,
        loading,
        can,
        locale,
        printLoading,
        onTypeChange,
        shipmentStatuses: computed(() => store.getters.shipmentStatusesList),
        onReset,
        disClass,
        printBtn,
        excelHeader,
        excelData: computed(() => store.getters.exportShipmentsList),
      };
    },
  });
